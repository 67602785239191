import React from 'react'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Info = () => {
  const ref = useOnScrollAnimate()

  return (
    <section ref={ref} className="text-center">
      <div className="mx-auto my-0 w-10/12 sm:w-8/12 bg-white py-14 relative -mt-20 md:-mt-32 lg:-mt-36 bg-opacity-80 anim fadeInUp">
        <h1 className="great-vibes text-primary text-7xl md:text-8xl mb-5 leading-[0.95] md:leading-[1]">
          Lupita <br className="md:hidden" />
          <span className="text-gray-600 text-4xl md:text-6xl mx-2">&</span>
          <br className="md:hidden" /> Andrés
        </h1>
        <h2 className="md:text-xl tracking-wide anim fadeInUp delay-500">
          <strong>NUESTRA BODA</strong>
        </h2>
        <h2 className="md:text-lg mb-12 tracking-c1 anim fadeInUp delay-800">
          7 de Septiembre 2024
        </h2>
        <p className="anim fadeInUp delay-1000">
          SOLO HAY DOS MOMENTOS <br />
          EN QUE QUIERO ESTAR CONTIGO: <br />
          <strong className="text-xl">AHORA Y SIEMPRE</strong>
        </p>
      </div>
    </section>
  )
}

export default Info
