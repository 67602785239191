import Confirmation from './sections/Confirmation'
import Counter from './sections/Counter'
import Gallery from './sections/Gallery'
import Header from './components/Header'
import Info from './sections/Info'
import ImageBanner from './sections/ImageBanner'
import lupitaAndres from './assets/img/lupita_andres.jpg'
import lupitaAndres6 from './assets/img/lupita_andres_6.jpg'
import Footer from './components/Footer'

const App = () => {
  return (
    <div className="App">
      <Header />
      <ImageBanner
        src={lupitaAndres}
        className="object-[center_32%] max-h-[500px] md:max-h-[700px]"
      />
      <Info />
      <Counter />
      <Confirmation />
      <Gallery />
      <ImageBanner
        src={lupitaAndres6}
        overlay
        className="object-[center_80%] max-h-[450px] md:max-h-[600px]"
      />
      <Footer />
    </div>
  )
}

export default App
