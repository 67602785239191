import React from 'react'
// import mesaRegalos from '../assets/img/mesa_regalos_title.svg'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Footer = () => {
  // const ref = useOnScrollAnimate()
  const ref2 = useOnScrollAnimate()
  return (
    <>
      {/* <section ref={ref} className="py-12 bg-primary text-center text-white">
        <h1 className="great-vibes text-5xl text-white mb-4">
          Mesa de Regalos
        </h1>
        <p className="mb-8 md:mb-4 text-sm md:text-base anim fadeInUp delay-300">
          SIN TI ESTO NO SERÍA IGUAL. <br />
          GRACIAS POR TU COMPAÑÍA EN ESTA NUEVA ETAPA QUE COMENZAMOS <br />
          EL REGALO ES OPCIONAL, LA ASISTENCIA ES OBLIGATORIA, <br />
          PERO SI QUIERES TENER UN DETALLE CON NOSOTROS <br />
          ¡SUMA KILÓMETROS A NUESTRA LUNA DE MIEL!.
        </p>
        <p className="anim fadeInUp delay-500">5142 - 3265 - 4444 - 1515</p>
      </section> */}
      <section
        ref={ref2}
        className="pt-8 pb-16 bg-[#4D4D4D] text-center text-white"
      >
        <p className="leading-5 anim fadeInUp delay-300">
          &copy; DESARROLLADO POR <br />
          <a
            className="underline hover:text-blue-300"
            href="https://nodolab.com.mx"
            target="_blank"
            rel="noreferrer"
          >
            NODOLAB
          </a>
        </p>
      </section>
    </>
  )
}

export default Footer
