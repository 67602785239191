import React from 'react'
// import confirmationImage from '../assets/img/confirmacion_title.svg'
import MapCard from '../components/MapCard'
import WhatsAppIcon from '../assets/img/whatsapp.svg'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'
import temploCruz from '../assets/img/templo_cruz.jpg'
import bodaCivil from '../assets/img/boda_civil.jpg'
import terrazaCasaGrande from '../assets/img/terraza_casa_grande.jpg'

const Confirmation = () => {
  const ref1 = useOnScrollAnimate()
  const ref2 = useOnScrollAnimate()
  const ref3 = useOnScrollAnimate()
  const ref4 = useOnScrollAnimate()
  return (
    <>
      <section className="py-12 text-center">
        <div ref={ref1}>
          <h1 className="great-vibes text-primary text-5xl anim fadeIn delay-300">
            Confirmación de Asistencia
          </h1>
        </div>
        <div ref={ref2} className="py-4 flex justify-around flex-wrap mb-2">
          <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-500">
            <MapCard
              title="CEREMONIA RELIGIOSA"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1579.3037543584092!2d-101.1906304617275!3d19.70246664120526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e722e5e82f1%3A0xb6ea93eb7df3d462!2sRector%C3%ADa%20de%20la%20Santa%20Cruz!5e0!3m2!1ses-419!2smx!4v1720849346671!5m2!1ses-419!2smx"
              image={temploCruz}
              srcMaps="https://maps.app.goo.gl/jzqt21ycmcrQGoky6"
            >
              <strong>TEMPLO DE LA CRUZ</strong> <br />
              Avenida Francisco I. Madero S/N Centro histórico, Morelia, Mich.{' '}
              <br />
              <strong className="text-xl">17:00 HRS.</strong>
            </MapCard>
          </div>
          <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-800">
            <MapCard
              title="CIVIL"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.2475873175463!2d-101.19678798859117!3d19.70208053194183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e70607dea63%3A0x937bc9ca56599a37!2sCasa%20Grande!5e0!3m2!1ses-419!2smx!4v1720849799916!5m2!1ses-419!2smx"
              image={bodaCivil}
              srcMaps="https://maps.app.goo.gl/fCybBGBRXQswvz788"
            >
              <strong>HOTEL CASA GRANDE</strong> <br />
              Portal Matamoros 98, Centro, Morelia, Mich. <br />
              <strong className="text-xl">18:30 HRS.</strong>
            </MapCard>
          </div>
          <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-1100">
            <MapCard
              title="RECEPCIÓN"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.2475873175463!2d-101.19678798859117!3d19.70208053194183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e70607dea63%3A0x937bc9ca56599a37!2sCasa%20Grande!5e0!3m2!1ses-419!2smx!4v1720849799916!5m2!1ses-419!2smx"
              image={terrazaCasaGrande}
              srcMaps="https://maps.app.goo.gl/fCybBGBRXQswvz788"
            >
              <strong>TERRAZA HOTEL CASA GRANDE</strong> <br />
              Portal Matamoros 98, Centro, Morelia, Mich.
            </MapCard>
          </div>
        </div>
        <div ref={ref3}>
          <p className="text-primary font-bold md:text-lg mb-2 md:mb-4 anim fadeInUp delay-500">
            AGRADECEMOS TU CONFIRMACIÓN AL
          </p>
          <p className="mb-3 md:mb-4 text-primary text-xl md:text-2xl flex justify-center anim fadeInUp delay-800">
            <a
              href="https://wa.me/+524432417010"
              target="_blank"
              rel="noreferrer"
              className="flex items-end"
            >
              <span className="inline-block w-5 md:w-6 mr-3">
                <img src={WhatsAppIcon} alt="" />
              </span>
              <span>443 241 7010</span>
            </a>
          </p>
          <p className="md:text-lg anim fadeInUp delay-1000">
            CÓDIGO DE VESTIMENTA
          </p>
          <div className="flex items-center justify-center pt-2 anim fadeInUp delay-1200">
            <strong className="pt-0 pb-1 mb-2 px-3 rounded-md bg-primary text-white">
              ELEGANTE
            </strong>
          </div>
          <p className="md:text-lg text-lg tracking-wide anim fadeInUp delay-1300">
            <strong>- SOLO ADULTOS -</strong>
          </p>
        </div>
      </section>
      <section ref={ref4} className="bg-primary py-16 text-white ">
        <div className="flex justify-center flex-wrap gap-10 md:flex-nowrap md:gap-0">
          <div className="w-full lg:w-1/4 text-center anim fadeInUp delay-500">
            <h2 className="text-3xl great-vibes font-semibold pb-2">
              Padres de la Novia
            </h2>
            <p>María Guadalupe Chagolla Farías</p>
            <p>Moisés Díaz Díaz ✝</p>
          </div>
          <div className="w-full lg:w-1/4 text-center anim fadeInUp delay-800">
            <h2 className="text-3xl great-vibes font-semibold pb-2">
              Padres del Novio
            </h2>
            <p>Guadalupe Leticia Gutiérrez Cervantes ✝</p>
            <p>Armando Guillén Osorio</p>
          </div>
          <div className="w-full lg:w-1/4 text-center anim fadeInUp delay-1100">
            <h2 className="text-3xl great-vibes font-semibold pb-2">
              Padrinos
            </h2>
            <p>Raymundo López Olvera</p>
            <p>Nancy Verónica Rodriguez Cervantes</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Confirmation
